/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div
      style={{ backgroundColor: "#203864" }}
      className="d-flex h-100 text-center text-white"
    >
      <div
        style={{ maxWidth: "42em" }}
        className={
          "cover-container d-flex w-100 h-100 p-3 mx-auto flex-column "
        }
      >
        <header className="mb-auto"></header>

        <main className="px-3 ">
          <h1>Xola dos Santos. A Software Engineer based in Cape Town, ZA.</h1>
          <br />
          <p className="lead">
          Analytical, organised and detail-oriented front-end software engineer with TypeScript, React, Node,
SQL and experience in the full SDLC of financial software development. Collaborative team player
with ownership mentality and a track record of delivering production-quality software in South Africa,
Canada, Australia, UK and New Zealand.
          </p>
          <br />

          <Link className="lead" to="/about">
            <div
              style={{ color: "#333" }}
              className="btn btn-lg btn-secondary fw-light border-white bg-white"
            >
              More about me
            </div>
          </Link>
        </main>

        <footer className="mt-auto text-white-50">
          <p>Xola dos Santos Resumé</p>
        </footer>
      </div>
    </div>
  );
};

export default Home;

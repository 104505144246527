/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import Nav from "../../components/nav";

const About = () => {
  return (
    <>
      <Nav />
      <main className="container">


        <div
          style={{ backgroundColor: "#203864" }}
          className="p-4 p-md-5 mb-4 text-white rounded"
        >
          <div className="col-md-6 px-0">
            <h1 className="display-4 fst-italic">More About Me</h1>
            <p className="lead my-3">
              Analytical, organised and detail-oriented front-end software engineer with TypeScript, React, Node,
              SQL and experience in the full SDLC of financial software development. Collaborative team player
              with ownership mentality and a track record of delivering production-quality software in South Africa,
              Canada, Australia, UK and New Zealand.
            </p>
            <p className="lead">
              <a
                style={{ color: "#000000" }}
                href="./downloads/Xola_dos_Santos_Brief_Engineer_Résumé.pdf"
                target="_blank"
                className="btn btn-lg btn-secondary fw-light border-white bg-white"
              >
                Download Resumé PDF
              </a>
            </p>
          </div>
        </div>


        <div className="row g-5">
          <div className="col-md-8">
            <h2 className="pb-4 mb-4 fst-italic border-bottom">
              Some work experience
            </h2>

            <article className="blog-post">
              <h4>ClearScore (Pty) Ltd</h4>
              <p>Mid-Level Software Engineer</p>
              <h6>Jan 2022 – Nov 2023</h6>
              <p>
                Web development for using TypeScript. Writing unit and integration tests using Cypress,
                React Testing Library, NX, and Jest. I also played a key role in onboarding
                and coaching new engineers. My role involved remote collaboration
                with developers across Europe, Canada, New Zealand, and Australia, working
                closely with Scala backend engineers and data scientists. Monitored releases,
                app performance using Sentry, Amplitude, and DOMO
              </p>

              <h4 id="renderheads">RenderHeads (Pty) Ltd</h4>
              <p>Junior Software Engineer</p>
              <h6>Sept 2020 – Jan 2022</h6>
              <p>
                At RenderHeads, I developed mobile games using React and TypeScript, 
                collaborated with major South African brands, and worked with .NET backend 
                developers. I also used cutting-edge technologies like webSockets and 
                long-polling HTTP connections.
              </p>

              <h4>
                University of Stellenbosch Business School Executive Development
                (Pty) Ltd
              </h4>
              <p>Digital Marketing Coordinator</p>
              <h6>Dec 2018 - Feb 2020</h6>
              <p>
                Leading video campaigns, analytics, web and social marketing in
                order to execute clear, personalised messaging across multiple
                online channels.
              </p>

              <h4>Juta and Company (Pty) Ltd</h4>
              <p>Digital Marketing Coordinator</p>
              <h6>Jan 2016 – Oct 2018</h6>
              <p>
                Craft concept and content for press releases and email marketing
                campaigns. Use HTML, CSS etc to build landing pages and mails
                for campaigns.
              </p>

              <h4>Book Lounge</h4>
              <p>Bookseller</p>
              <h6>Feb 2014 – Nov 2015</h6>

              <p>
                As a bookseller I used to help customers with enquiries and
                orders, as well as being responsible for stock control. On the
                weekends I would help with answering enquiries and ordering
                books for customers.
              </p>
            </article>
          </div>

          <div className="col-md-4">
            <div className="position-sticky" style={{ top: "2rem" }}>
              <div className="p-4 mb-3 bg-light rounded">
                <h4 className="fst-italic">About</h4>

                <p>
                  I help individuals and companies use software to boost
                  business performance. Passionate about Digital Marketing,
                  Software Engineering {"&"} Analytics.
                </p>
                <p>
                  I am currently employed as a Software Engineer at ClearScore
                  (Pty) Ltd.
                </p>
                <p>
                  I have been programming in JavaScript since High School and
                  took the leap, switched careers from being a Digital Marketing
                  Specialist to Software Engineer in February 2020.
                </p>
              </div>
            </div>
          </div>
        </div>



        <br></br>
        <footer className="blog-footer">
          <p>Xola dos Santos Resumé</p>
          <a href="#header">Back to top</a>
        </footer>
        <br></br>
      </main>
    </>
  );
};

export default About;

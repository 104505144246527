import React from "react";
import { Link } from "react-router-dom";

const Nav = () => {
  return (
      <div className="container">
        <header id="header" className="blog-header py-3">
          <div className="nav-scroller py-1 mb-2">
            <nav className="nav d-flex">
              <Link className="p-2 link-secondary" to="/">
                Home
              </Link>
              <Link className="p-2 link-secondary" to="/about">
                About
              </Link>
              {/*<Link className="p-2 link-secondary" to="/recruit">*/}
              {/*  Recruit Engine*/}
              {/*</Link>*/}
            </nav>
          </div>
        </header>
      </div>
  );
};

export default Nav;

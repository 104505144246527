/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Nav from "../../components/nav";

const RecruitLanding = () => {
  return (
    <>
      <Nav />

      <main className="container">
        <div
          style={{ backgroundColor: "#203864" }}
          className="p-4 p-md-5 mb-4 text-white rounded"
        >
          <div className="col-md-6 px-0">
            <h1 className="display-4 fst-italic">The Recruit Engine </h1>
            <p className="lead my-3">
              The Recruit Engine is a webapp where talented individuals can
              create profiles and apply for jobs
            </p>
          </div>
        </div>

        <div className="row g-5">
          <div className="col-md-8 ">
            <div className="p-4 mb-3 ">
              <h4 className="fst-italic">Why do we need this software? </h4>
              <p>
                Young engineers studying technology, data science and software
                engineering struggle to find opportunities to apply theories
                from their academic work, despite their skills being in high
                demand.
              </p>
              <p>
                At the same time talent acquisition specialist at companies
                undergoing digital transformation struggle to find the right
                tech talent in order to implement their various digital
                transformation strategies.
              </p>
              <p>
                The problem is a pipeline of outsourced talent needs to be
                continuously available at the right price, at the right time at
                the right quality, and from a platform evaluated by
                professionals.
              </p>

              <p>
                The Recruit Engine is a LinkedIn-like SAAS application developed
                in order to solve this simple problem by bridging this
                “knowledge gap”.
              </p>
            </div>
            <div id="xdode_5601858699198464" className="agile_crm_form_embed">
              Fill out my{" "}
              <a href="https://xdode.agilecrm.com/forms/5601858699198464">
                online form
              </a>{" "}
              to get updates on the Recruit Engine webapp.
            </div>
          </div>
          <div className="col-md-4">
            <div className="position-sticky" style={{ top: "2rem" }}>
              <div className="p-4 mb-3 bg-light rounded">
                <h4 className="fst-italic">About Me</h4>

                <p>
                  I help individuals and companies use software to boost
                  business performance. Passionate about Digital Marketing,
                  Software Engineering {"&"} Analytics.
                </p>
                <p>
                  I am currently employed as a Software Engineer at ClearScore
                  (Pty) Ltd.
                </p>
                <p>
                  I have been programming in JavaScript since High School and
                  took the leap, switched careers from being a Digital Marketing
                  Specialist to Software Engineer in February 2020.
                </p>
              </div>
            </div>
          </div>
        </div>

        <br></br>

        <footer className="blog-footer">
          <p>Xola dos Santos</p>
          <a href="#">Back to top</a>
        </footer>
      </main>
    </>
  );
};

export default RecruitLanding;

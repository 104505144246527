import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

import { Routes, Route } from "react-router-dom";
import About from './pages/about';
import Home from "./pages/home";
import RecruitLanding from "./pages/recruit-engine";
import './App.css';
import Examples from './pages/examples';

const App = () => {
  return (
    <div style={{ height: "100vh" }}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="recruit" element={<RecruitLanding />} />
        <Route path="examples" element={<Examples />} />
        <Route path="recruit-engine.htm" element={<RecruitLanding />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;
